
<template>
    <div>
        <ul class="list-unstyled">
            <b-media tag="li">
                <template #aside>
                    <div class="avatar-sm">
                        <div class="avatar-title bg-light rounded text-body font-20 font-weight-semibold">1</div>
                    </div>
                </template>
                <h5 class="mt-0 mb-1">GROUP INFORMATION</h5>
                <p class="mb-0">
                    The creator of a language group becomes the Founder of the group.<br>
                    • Four (4) profiles in addition to the Founder must be subscribers of that language for
                    it to be verified as a language to be registered.<br>
                    • The first 4 people to subscribe to the group are the co-founders of the group.
                </p>
            </b-media>

            <b-media tag="li" class="mt-4">
                <template #aside>
                    <div class="avatar-sm">
                        <div class="avatar-title bg-light rounded text-body font-20 font-weight-semibold">2</div>
                    </div>
                </template>

                <h5 class="mt-0 mb-1">UPLOADING TO THE GROUP</h5>
                <p class="mb-0">
                    • Members of a language group will be able to upload content to the language group
                    they are in.<br>
                    • For any content uploaded by members of a group, there will be a peer review period
                    of 7 days. The result from the review of the uploaded content will determine if the content would be approved or not.<br>
                    • As a member of language group, you are required to view uploaded contents and endorse or dispute them. This is necessary for uploaded content to be approved or rejected in the language group
                </p>
            </b-media>

            <b-media tag="li" class="my-4">
                <template #aside>
                    <div class="avatar-sm">
                        <div class="avatar-title bg-light rounded text-body font-20 font-weight-semibold">3</div>
                    </div>
                </template>

                <h5 class="mt-0 mb-1">RECOMMENDED STRUCTURE FOR CONTENT UPLOAD</h5>
                <p class="mb-0">
                    • <strong> Beginner Level: </strong> Alphabets, 5 Letter Words or less, Beginner Level Quizzes.<br>
                    • <strong> Junior Level: </strong> All words, Phrases/Sentences of 5 Words or less, Junior Level Quizzes. <br>
                    • <strong> Senior Level: </strong> All Phrases/Sentences, Senior Level Quizzes. <br>
                    • <strong> Advanced Level: </strong> Essays (at least 5 to be written by Learner and each graded by 2 Teachers), Advanced Level Quizzes
                </p>
            </b-media>
        </ul>
    </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },

}
</script>
