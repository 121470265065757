

<template>
  <div id="upload-word">
    <div class="row">
        <div class="col-6 d-flex align-items-center border-right">
            <div class="w-100">
                <div class="form-group mb-3">
                    <input type="text" class="form-control rounded-0 border-left-0 border-right-0 border-top-0" v-model="data.content" v-validate="'required|alpha_spaces|max:50'" placeholder="Enter word" name="word" />
                    <span class="text-danger font-sm"  v-show="errors.has('word')">{{ errors.first('word') }}</span>
                </div>
                <div class="form-group mb-3">
                    <input type="text" class="form-control rounded-0 border-left-0 border-right-0 border-top-0" v-model="data.translation" placeholder="Translation" name="translation" />
                </div>
                <div>
                    <!-- <label style="line-height:1font-size:14px;">Select Level</label> -->
                    <b-form-select v-validate="'required'" v-model="data.level" name="level" class="rounded-0 border-left-0 border-right-0 border-top-0 mb-0">
                        <template #first>
                            <b-form-select-option :value="null" disabled>-- Select Level --</b-form-select-option>
                        </template>
                        <b-form-select-option value="beginner">Beginner</b-form-select-option>
                        <b-form-select-option value="junior">Junior</b-form-select-option>
                        <b-form-select-option value="senior">Senior</b-form-select-option>
                        <b-form-select-option value="advanced">Advanced</b-form-select-option>
                    </b-form-select>
                    <span class="text-danger font-sm"  v-show="errors.has('level')">{{ errors.first('level') }}</span>
                </div>
            </div>
        </div>
         <div class="col-6">
          <audio-recorder :time="10" @exportFile="setVoice($event)" @removeFile="setVoice(null)" />
        </div>
    </div>
    <!-- <hr /> -->
    <div class="row mt-3">
        <div class="col-12">
            <div class="form-group">
                <label class="text-center">Add Sentence Usage</label>
                <div class="mb-2">
                    <b-input-group class="mb-1">
                        <b-form-input v-model="data.first_usage" v-validate="'required'" name="first_usage" aria-label="First Usage" placeholder="First Usage"></b-form-input>
                        <b-form-input v-model="data.first_usage_trans" name="first_usage_trans" aria-label="First Usage Translation" placeholder="Translation"></b-form-input>
                    </b-input-group>
                     <span class="text-danger font-sm"  v-show="errors.has('first_usage')">{{ errors.first('first_usage') }}</span>
                </div>
                <div class="mb-2">
                    <b-input-group class="mb-1">
                        <b-form-input v-model="data.second_usage" v-validate="'required'" name="second_usage" aria-label="Second Usage" placeholder="Second Usage"></b-form-input>
                        <b-form-input v-model="data.second_usage_trans" name="second_usage_trans" aria-label="Second Usage Translation" placeholder="Translation"></b-form-input>
                    </b-input-group>
                     <span class="text-danger font-sm"  v-show="errors.has('second_usage')">{{ errors.first('second_usage') }}</span>
                </div>
                <div>
                    <b-input-group class="mb-1">
                        <b-form-input v-model="data.third_usage" v-validate="'required'" name="third_usage" aria-label="Third Usage" placeholder="Third Usage"></b-form-input>
                        <b-form-input v-model="data.third_usage_trans" name="third_usage_trans" aria-label="Third Usage Translation" placeholder="Translation"></b-form-input>
                    </b-input-group>
                     <span class="text-danger font-sm"  v-show="errors.has('third_usage')">{{ errors.first('third_usage') }}</span>
                </div>

            </div>
        </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="mt-3">
          <b-button variant="primary" @click.prevent="editMode? update() : create()" type="button">{{editMode ? 'Update':'Submit'}}</b-button>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import AudioRecorder from '@/components/AudioRecorder.vue';

export default {
  components:{
    AudioRecorder
  },
  props:{
    editItem:{
      type: Object,
      default: null
    },
    editMode:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: {
        content: "",
        translation: "",
        first_usage: "",
        second_usage: "",
        third_usage: "",
        first_usage_trans: "",
        second_usage_trans: "",
        third_usage_trans: "",
        level: null,
        group_id: this.$route.params.groupId
      },
      file: null
    }
  },
  methods: {
    create() {
      this.$validator.validateAll().then(result => {
        if (!result) {this.alertError("Form not valid"); return }
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        if(this.file){ formData.append('voice_file', this.file.blob, 'voice-record.mp3') }
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post('/posts/save-word', formData)
        .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
                this.$store.commit("groupList/ADD_POST", response.data.data)
                this.resetForm();
            }
        })
      })
    },
    update(){
      this.$validator.validateAll().then(result => {
        if (!result) {this.alertError("Form not valid"); return }
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        if(this.file){ formData.append('voice_file', this.file.blob, 'voice-record.mp3') }
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/posts/${this.editItem.id}/update`,formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$store.commit("groupList/UPDATE_POST", response.data.data)
            this.resetForm()
          }
        })
      })
    },
    setVoice(file){
      this.file = file
    },
    resetForm() {
      this.file = null
      this.$emit('closeMe');
    },
  },
  mounted(){
    if(this.editMode){
      this.data = this.$options.filters
                  .patchUpdatables(this.data, this.editItem)
    }
  }
}
</script>
