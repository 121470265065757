<template>
<div v-if="post" >
    <div>
        <div class="">
            <div class="media mt-1">
                <div class="media-body">
                    <span class="float-right"><small>{{post.created_at | date_time(1)}}</small></span>
                    <!-- <span class="float-right"><span> <span class="border-right cursor-pointer d-inline-block" v-b-tooltip.hover title="Endorse">{{post.endorses.length}} <i class="mdi mdi-thumb-up d-inline-block mr-1"></i></span> <span class="cursor-pointer" v-b-tooltip.hover title="Dispute"> {{post.disputes.length}} <i class="mdi mdi-thumb-down d-inline-block"></i></span></span></span> -->
                    <b-button @click.prevent="$emit('returnBack')" variant="light" size="sm">
                        <i class="mdi mdi-chevron-left"></i> View list
                    </b-button> <br>
                </div>
            </div>
        </div>
        <hr class="my-2" />
        <p>
            <span class="bg-soft-success badge">Level: {{ post.level}}</span>
        </p>
        <dl class="row mt-3">
            <dt class="col-12">Topic:</dt>
            <dd class="col-12"><div class="pl-3"> {{post.topic}}</div></dd>
            <dt class="col-12">Content:</dt>
            <dd class="col-12"><div class="pl-3"><div v-html="preText(post.content)"></div></div></dd>
            <dt class="col-12">Translation:</dt>
            <dd class="col-12"><div class="pl-3"><div v-html="preText(post.translation)"></div></div></dd>
        </dl>
        <hr class="my-2" />
        <div v-if="post.voice_path" class="row">
            <div  class="col-sm-6 col-lg-5">
                <h5 class="mb-2">Voice Attachments</h5>
                 <div>
                    <audio
                        hieght="23"
                        controls
                        :src="absoluteUrl(post.voice_path)">
                            Your browser does not support the
                            <code>audio</code> element.
                    </audio>
                </div>
            </div>
            <div class="col-12"><hr /></div>
        </div>
        <div>
            <div class="d-flex justify-content-between align-items-center">
                <h5 class="mb-2">Reviews</h5>
                <a v-if="!isReviewer" @click.prevent="popupReviewModal = true" href="#" class="btn btn-primary mr-2 btn-sm">
                    <i class="mdi mdi-check-all mr-1"></i> Review Essay
                </a>
            </div>
            <div v-if="post.comments.length" >
                <ul class="list-group list-group-flush">
                    <li v-for="(comment, incom) in post.comments" :key="incom" class="list-group-item px-0 mb-0 bg-transparent">
                        <div class="media align-items-center">
                            <div class="mr-3">
                                <strong>Teacher {{incom+1}}</strong>
                                <h5 class="my-1">{{comment.score}}%</h5>
                            </div>
                            <div class="media-body">
                                <span v-if="comment.score < 50" class="bg-soft-danger text-danger badge mr-2">Poor</span>
                                <span v-if="comment.score > 49" class="bg-soft-success text-success badge mr-2">Passed</span>
                                <dd> {{comment.remark}}</dd>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <b-modal centered scrollable id="modal-1" size="md" v-model="popupReviewModal"
        title="Provide your Remark and Score" header-close-variant="primary"
        title-class="font-18 text-primary" hide-footer @hide="initializeAll()">
        <essay-review-form v-if="popupReviewModal" :essayId="post.id" @closeMe="initializeAll()"/>
    </b-modal>
</div>
</template>

<script>
import EssayReviewForm from '@/components/forms/EssayReviewForm.vue';

export default {
  components: { EssayReviewForm },
    props:{
        post:{
            type: Object,
            default: null
        },
    },
    data(){
        return {
            popupReviewModal: false
        }
    },
    computed: {
        activeUser() {
            return this.$store.state.AppActiveUser
        },
        isReviewer(){
            return this.post.comments.map((c) => c.teacher_id).includes(this.activeUser.id)
        },
    },
    methods: {
        initializeAll(){
            this.popupReviewModal = false
        },
    }

};
</script>

<style>

audio {
    width: 100%;
    height: 30px;
}
</style>
