<template>
  <div class="card">
    <div class="card-header bg-white">
        <h4 class="mb-0">Essay written by learners</h4>
    </div> 
    <div v-if="isLoading" class="card-disabled">
        <div class="card-portlets-loader">
            <div class="spinner-border text-primary m-2" role="status"></div>
        </div>
    </div>
    <div class="card-body">
        <div v-if="!isEssayView">
            <div>
                <form class="m-0">
                    <div class="input-group">
                        <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control form-control-white"
                            placeholder="Search for content..." />
                        <div class="input-group-prepend">
                            <b-dropdown variant="secondary" right>
                                <template slot="button-content">
                                    <i class="mdi mdi-filter-variant"></i>
                                </template>
                                <b-dropdown-item-button @click="fetchBySort('asc')"> Ascending </b-dropdown-item-button>
                                <b-dropdown-item-button @click="fetchBySort('desc')"> Descending </b-dropdown-item-button>
                                <b-dropdown-item-button @click="fetchByStatus('pending')"> Pending </b-dropdown-item-button>
                                <b-dropdown-item-button @click="fetchByStatus('reviewed')"> Reviewed </b-dropdown-item-button>
                                <b-dropdown-item-button @click="fetchByStatus('approved')"> Approved </b-dropdown-item-button>
                                <b-dropdown-item-button @click="fetchByStatus('all')"> View all </b-dropdown-item-button>
                            </b-dropdown>
                        </div>
                    </div>
                </form>
            </div>
            <div class="mt-2 mb-3">
                <strong>Filters:</strong> <span>Sort: <span class="text-uppercase">{{essaySort}}</span></span> | <span>Status: <span class="text-uppercase">{{essayStatus}}</span></span>
            </div>
            <div v-if="essays.length">
                <div class="table-responsive mb-0">
                    <table class="table table-borderless table-hover table-centered m-0">
                        <thead class="">
                            <tr>
                                <th class="pl-0">Topic</th>
                                <th>Content</th>
                                <th>Status</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr @click="openEssayView(essay)" v-for="(essay, index) in essays" :key="index" class="cursor-pointer font-14" >
                                <td class="pl-0">{{ essay.topic | truncate(30)}}</td>
                                <td>{{ essay.content | truncate(30)}}</td>
                                <td>
                                    <span v-html="statusBadge(essay.status)"></span>
                                </td>
                                <td class="font-12">
                                    <div style="min-width:5rem;">{{ essay.created_at | date_ago }}</div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="">
                    <b-pagination v-model="currentPage" class="pagination-rounded mb-0"
                            :total-rows="essayData.total" :limit="3" :per-page="itemsPerPage"></b-pagination>
                </div>
            </div>
            <div v-else class="pt-3">
                There are no essay to show.
            </div>
        </div>
        <div v-if="isEssayView">
            <essay-content @returnBack="closeEssayView()" :post="viewEssay" />
        </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import EssayContent from '@/components/group/EssayContent.vue';

export default {
    components:{
        EssayContent
    },
    data(){
        return{
            isLoading: true,
            searchQuery: "",
            essayStatus: "all",
            essaySort: "asc",
            viewEssay: null,
            isEssayView: false
        }
    },
    computed: {
        essayData() {
            return this.$store.state.groupList.essayData
        },
        essays() {
            return this.essayData.data
        },
        currentPage: {
            get() {
                return this.essayData.current_page
            },
            set(val) {
                if(!this.essayData.current_page || this.essayData.current_page == val) return
                this.fetchItems(val)
            }
        },
        itemsPerPage: {
            get() {
                return +this.essayData.per_page || 50
            },
            set(val) {
                this.fetchItems(1, val)
            }
        },
    },
    methods:{
        openEssayView(item){
            this.isEssayView = true
            this.viewEssay = item;
        },
        closeEssayView(){
            this.isEssayView = false;
        },
        fetchByStatus(status){
            this.essayStatus = status;
            this.isEssayView = false;
            this.fetchEssays()
        },
        fetchBySort(sort){
            this.essaySort = sort;
            this.isEssayView = false;
            this.fetchEssays()
        },
        updateSearchQuery: _.debounce(function(string) {
            this.fetchEssays()
        }, 2000),
        fetchEssays(page, per_page = null){
            let payload = {group_id: this.$route.params.groupId, page: page || 1, sort_by: this.essaySort, per_page: per_page || 5 }
            if(this.essayStatus != 'all'){ payload.status = this.essayStatus }
            if(this.searchQuery){ payload.search = this.searchQuery }
            this.isLoading = true
            this.$store.dispatch("groupList/fetchEssays", payload)
            .then(response => this.isLoading = false)
        }
    },
    mounted(){
        this.fetchEssays()
    }
}
</script>

<style>

</style>