

<template>
  <div id="upload-quiz">
    <div class="row">
        <div class="col-sm-7 col-md-8">
            <div class="row">
                <div class="col-12">
                    <div class="form-group mb-3 mb-0 text-center w-100">
                        <textarea  v-model="data.content" class="form-control bg-light border-0 rounded-0" rows="2" placeholder="Question..." v-validate="'required|max:100'" name="question"></textarea>
                        <span class="text-danger font-sm"  v-show="errors.has('question')">{{ errors.first('question') }}</span>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group mb-3">
                        <label>Provide Options</label>
                        <div class="input-group input-group-merge">
                            <div class="input-group-append">
                                <div class="input-group-text border-left-0 border-right-0 border-top-0">
                                    A
                                </div>
                            </div>
                            <input v-validate="'required|max:50'" v-model="data.option_a" name="option_a" :class="{ 'is-invalid': errors.has('option_a') }" type="text"  placeholder="Enter Option A" class="form-control rounded-0 border-left-0 border-right-0 border-top-0" />
                        </div>
                        <span class="text-danger font-sm"  v-show="errors.has('option_a')">{{ errors.first('option_a') }}</span>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group mb-3">
                        <div class="input-group input-group-merge">
                            <div class="input-group-append">
                                <div class="input-group-text border-left-0 border-right-0 border-top-0">
                                    B
                                </div>
                            </div>
                            <input v-validate="'required|max:50'" v-model="data.option_b" name="option_b" :class="{ 'is-invalid': errors.has('option_b') }" type="text"  placeholder="Enter Option B" class="form-control rounded-0 border-left-0 border-right-0 border-top-0" />
                        </div>
                        <span class="text-danger font-sm"  v-show="errors.has('option_b')">{{ errors.first('option_b') }}</span>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group mb-3">
                        <div class="input-group input-group-merge">
                            <div class="input-group-append">
                                <div class="input-group-text border-left-0 border-right-0 border-top-0">
                                    C
                                </div>
                            </div>
                            <input v-validate="'required|max:50'" v-model="data.option_c" name="option_c" :class="{ 'is-invalid': errors.has('option_c') }" type="text"  placeholder="Enter Option C" class="form-control rounded-0 border-left-0 border-right-0 border-top-0" />
                        </div>
                        <span class="text-danger font-sm"  v-show="errors.has('option_c')">{{ errors.first('option_c') }}</span>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group mb-3">
                        <div class="input-group input-group-merge">
                            <div class="input-group-append">
                                <div class="input-group-text border-left-0 border-right-0 border-top-0">
                                    D
                                </div>
                            </div>
                            <input v-validate="'required|max:50'" v-model="data.option_d" name="option_d" :class="{ 'is-invalid': errors.has('option_d') }" type="text"  placeholder="Enter Option D" class="form-control rounded-0 border-left-0 border-right-0 border-top-0" />
                        </div>
                        <span class="text-danger font-sm"  v-show="errors.has('option_d')">{{ errors.first('option_d') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-5 col-md-4">
            <div class="row">
                <div class="col-12">
                    <!-- <label style="line-height:1font-size:14px;">Select Level</label> -->
                    <b-form-select v-validate="'required'" v-model="data.level" name="level" class="border-0 mb-0">
                        <template #first>
                            <b-form-select-option :value="null" disabled>-- Level --</b-form-select-option>
                        </template>
                        <b-form-select-option value="beginner">Beginner</b-form-select-option>
                        <b-form-select-option value="junior">Junior</b-form-select-option>
                        <b-form-select-option value="senior">Senior</b-form-select-option>
                        <b-form-select-option value="advanced">Advanced</b-form-select-option>
                    </b-form-select>
                    <span class="text-danger font-sm"  v-show="errors.has('level')">{{ errors.first('level') }}</span>
                </div>
                <div class="col-12">
                    <hr/>
                </div>
                <div class="col-12">
                    <label style="line-height:1font-size:14px;">Correct option</label>
                    <b-form-group v-slot="{ ariaDescribedby }">
                        <b-form-radio v-model="data.answer" :aria-describedby="ariaDescribedby" name="answer" class="mb-2" value="A">Option A</b-form-radio>
                        <b-form-radio v-model="data.answer" :aria-describedby="ariaDescribedby" name="answer" class="mb-2" value="B">Option B</b-form-radio>
                        <b-form-radio v-model="data.answer" :aria-describedby="ariaDescribedby" name="answer" class="mb-2" value="C">Option C</b-form-radio>
                        <b-form-radio v-model="data.answer" :aria-describedby="ariaDescribedby" name="answer" class="mb-2" value="D">Option D</b-form-radio>
                    </b-form-group>
                    <span class="text-danger font-sm"  v-show="errors.has('answer')">{{ errors.first('answer') }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="mt-3">
                <b-button variant="primary" @click.prevent="editMode? update() : create()" type="button">{{editMode ? 'Update':'Submit'}}</b-button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  props:{
    editItem:{
      type: Object,
      default: null
    },
    editMode:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: {
        content: "",
        level: null,
        option_a: "",
        option_b: "",
        option_c: "",
        option_d: "",
        answer: "",
        group_id: this.$route.params.groupId
      },
    }
  },
  methods: {
    create() {
      this.$validator.validateAll().then(result => {
        if (!result) {this.alertError("Form not valid"); return }
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post('/posts/save-quiz', formData)
        .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
                this.$store.commit("groupList/ADD_POST", response.data.data)
                this.resetForm();
            }
        })
      })
    },
    update(){
      this.$validator.validateAll().then(result => {
        if (!result) {this.alertError("Form not valid"); return }
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/posts/${this.editItem.id}/update`,formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$store.commit("groupList/UPDATE_POST", response.data.data)
            this.resetForm()
          }
        })
      })
    },
    resetForm() {
      this.$emit('closeMe');
    },
  },
  mounted(){
    if(this.editMode){
      this.data = this.$options.filters
                  .patchUpdatables(this.data, this.editItem)
    }
  }
}
</script>
