
<template>
  <div id="upload-alphabet">
    <div class="row">
      <div class="col-6 d-flex align-items-center border-right">
        <div>
          <div class="form-group mb-3">
              <input type="text" class="form-control rounded-0 border-left-0 border-right-0 border-top-0" v-model="data.content" v-validate="'required|alpha_spaces|max:20'" placeholder="Enter alphabet..." name="alphabet" />
              <span class="text-danger font-sm"  v-show="errors.has('alphabet')">{{ errors.first('alphabet') }}</span>
          </div>
          <div>
              <!-- <label style="line-height:1font-size:14px;">Select Level</label> -->
              <b-form-select v-validate="'required'" v-model="data.level" name="level" class="rounded-0 border-left-0 border-right-0 border-top-0 mb-0">
                  <template #first>
                      <b-form-select-option :value="null" disabled>-- Select Level --</b-form-select-option>
                  </template>
                  <b-form-select-option value="beginner">Beginner</b-form-select-option>
                  <b-form-select-option disabled value="junior">Junior</b-form-select-option>
                  <b-form-select-option disabled value="senior">Senior</b-form-select-option>
                  <b-form-select-option disabled value="advanced">Advanced</b-form-select-option>
              </b-form-select>
              <span class="text-danger font-sm"  v-show="errors.has('level')">{{ errors.first('level') }}</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <audio-recorder :time="5" @exportFile="setVoice($event)" @removeFile="setVoice(null)" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="mt-3">
          <b-button variant="primary" @click.prevent="editMode? update() : create()" type="button">{{editMode ? 'Update':'Submit'}}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioRecorder from '@/components/AudioRecorder.vue';

export default {
  components:{
    AudioRecorder
  },
  props:{
    editItem:{
      type: Object,
      default: null
    },
    editMode:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: {
        content: "",
        level: null,
        group_id: this.$route.params.groupId
      },
      file: null
    }
  },
  methods: {
    create() {
      this.$validator.validateAll().then(result => {
        if (!result) {this.alertError("Form not valid"); return }
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        if(this.file){ formData.append('voice_file', this.file.blob, 'voice-record.mp3') }
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post('/posts/save-alphabet', formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$store.commit("groupList/ADD_POST", response.data.data)
            this.resetForm();
          }
        })
      })
    },
    update(){
      this.$validator.validateAll().then(result => {
        if (!result) {this.alertError("Form not valid"); return }
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        if(this.file){ formData.append('voice_file', this.file.blob, 'voice-record.mp3') }
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/posts/${this.editItem.id}/update`,formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$store.commit("groupList/UPDATE_POST", response.data.data)
            this.resetForm()
          }
        })
      })
    },
    setVoice(file){
      this.file = file
    },
    resetForm() {
      this.file = null
      this.$emit('closeMe');
    },
  },
  mounted(){
    if(this.editMode){
      this.data = this.$options.filters
                  .patchUpdatables(this.data, this.editItem)
    }
  }
}

</script>

