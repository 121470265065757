<template>
  <div>
    <div class="mb-4">
        <div class="table-responsive mb-0">
            <table class="table table-borderless table-hover table-centered m-0">
                <thead class="">
                    <tr>
                        <th class="pl-0">Types</th>
                        <th>Content</th>
                        <th>Level</th>
                        <th>Status</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr @click="$emit('openPostView', post)" v-for="(post, index) in posts" :key="index" class="cursor-pointer font-14" >
                        <td class="pl-0">
                            <h5 class="m-0 font-weight-normal text-capitalize" >{{ post.type }}</h5>
                        </td>
                        <td>{{ post.content | truncate(30)}}</td>
                        <td>{{ post.level }}</td>
                        <td>
                            <span v-html="statusBadge(post.status)"></span>
                        </td>
                        <td class="font-12">
                            <div style="min-width:5rem;">{{ post.created_at | date_ago }}</div></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row justify-content-md-between align-items-md-center">
        <div class="col-12">
            <b-pagination v-model="currentPage" class="pagination-rounded mb-0"
                    :total-rows="postData.total" :limit="3" :per-page="itemsPerPage"></b-pagination>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        fetchItems:{
            type: Function
        }
    },
    computed: {
        postData(){
            return this.$store.state.groupList.postData
        },
        posts() {
            return this.postData.data
        },
        currentPage: {
            get() {
                return this.postData.current_page
            },
            set(val) {
                if(!this.postData.current_page || this.postData.current_page == val) return
                this.fetchItems(val)
            }
        },
        itemsPerPage: {
            get() {
                return +this.postData.per_page || 50
            },
            set(val) {
                this.fetchItems(1, val)
            }
        },
    },
}
</script>

<style>

</style>