<template>
	<div class="group-show mt-3">
        <div v-if="!isLoading">
            <div>
                <group-portlet headertitle="Group Info" :open="true">
                    <div class="card-body pt-0">
                        <div class="row justify-content-center">
                            <div class="col-12 border-bottom text-center">
                                <h5 class="mb-1">Welcome to {{group.name}} Language Group</h5>
                                <p  class="mb-3">{{group.description}}</p>
                                <div class="font-12 mb-1">
                                    <span v-if="group.countries.length" class="mr-2">
                                        <dt class="d-inline">{{(group.countries.length>1)? "Countries": "Country"}}: </dt>
                                        <dd class="d-inline">{{group.countries.map((i) => i.name).join(', ')}}</dd>
                                    </span>
                                    <span>
                                        <dt class="d-inline">Region: </dt>
                                        <dd class="d-inline">{{group.region}}</dd>
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 text-center mt-1">
                                <span class="mr-2">
                                    <strong >Founder: </strong>
                                    <span class="text-capitalize">
                                        {{group.founder.username}}
                                    </span>
                                </span>
                                <span class="mr-2">
                                    <strong >President: </strong>
                                    <span class="text-capitalize">
                                        {{group.founder.username}}
                                    </span>
                                </span>
                                <span class="mr-2">
                                    <strong >Teachers: </strong>
                                    <span class="text-capitalize">
                                        {{group.teachers_count}}
                                    </span>
                                </span>
                                <span class="mr-2">
                                    <strong >Learners: </strong>
                                    <span class="text-capitalize">
                                        {{group.learners_count}}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </group-portlet>

                <div class="row mt-3">
                    <!-- Right Sidebar -->
                    <div class="col-12">
                        <div class="card">
                            <div v-if="isFetchingPosts" class="card-disabled">
                                <div class="card-portlets-loader">
                                    <div class="spinner-border text-primary m-2" role="status"></div>
                                </div>
                            </div>
                            <div v-if="group.status == 'approved' && group.belong" class="card-body">
                                <div class="mb-2 d-flex">
                                    <b-dropdown class="btn-group mr-2" variant="light">
                                        <template slot="button-content">
                                            <i class="mdi mdi-dots-vertical"></i>
                                        </template>
                                        <b-dropdown-item to="/groups">
                                            Go back
                                        </b-dropdown-item>
                                        <b-dropdown-item href="#" @click.prevent="showGuidelinesModal = true" >Guidelines</b-dropdown-item>
                                    </b-dropdown>
                                    <b-dropdown variant="light">
                                        <template slot="button-content">
                                            Filter by type
                                        </template>
                                        <b-dropdown-item-button @click="fetchPostByType('all')"> <i class="mdi mdi-text-box-multiple align-bottom mr-2"></i>All Posts </b-dropdown-item-button>
                                        <b-dropdown-item-button @click="fetchPostByType('alphabet')"> <i class="mdi mdi-tag-text-outline align-bottom mr-2"></i>Alphabets</b-dropdown-item-button>
                                        <b-dropdown-item-button @click="fetchPostByType('word')"> <i class="mdi mdi-file-document-outline align-bottom mr-2"></i>Words</b-dropdown-item-button>
                                        <b-dropdown-item-button @click="fetchPostByType('phrase')"> <i class="ri-file-list-2-line align-bottom mr-2"></i>Phrases/Sentences </b-dropdown-item-button>
                                        <b-dropdown-item-button @click="fetchPostByType('quiz')"> <i class="ri-questionnaire-line align-bottom mr-2"></i>Quiz</b-dropdown-item-button>
                                        <b-dropdown-item-button @click="fetchPostByType('essay')"> <i class="ri-newspaper-line align-bottom mr-2"></i>Essay</b-dropdown-item-button>
                                    </b-dropdown>
                                </div>
                                <div v-if="!isPostView">
                                    <div class="d-sm-flex align-items-center">
                                        <b-dropdown class="btn-group mr-1 mb-2 mb-sm-0"  variant="primary">
                                            <template slot="button-content">
                                                <i class="mdi mdi-message-draw font-16"></i> <span class="font-weight-bold font-16">Upload </span>
                                                <i class="mdi mdi-chevron-down"></i>
                                            </template>
                                            <b-dropdown-item @click.prevent="uploadAlphaModal = true" href="#">Alphabets</b-dropdown-item>
                                            <b-dropdown-item @click.prevent="uploadWordModal = true" href="#">Words</b-dropdown-item>
                                            <b-dropdown-item @click.prevent="uploadSentenceModal = true" href="#">Phrases/Sentences</b-dropdown-item>
                                            <b-dropdown-item @click.prevent="uploadQuizModal = true" href="#">Quizzes</b-dropdown-item>
                                            <b-dropdown-item @click.prevent="uploadEssayModal = true" href="#">Essay</b-dropdown-item>
                                        </b-dropdown>
                                        <form class="flex-grow-1 d-inline-block m-0">
                                            <div class="input-group">
                                                <input v-model="postSearchQuery" @input="updateSearchQuery" type="search" class="form-control form-control-white"
                                                    placeholder="Search for content..." />
                                                <div class="input-group-prepend">
                                                    <b-dropdown variant="secondary" right>
                                                        <template slot="button-content">
                                                            <i class="mdi mdi-filter-variant"></i>
                                                        </template>
                                                        <b-dropdown-item-button @click="fetchPostBySort('asc')"> Ascending </b-dropdown-item-button>
                                                        <b-dropdown-item-button @click="fetchPostBySort('desc')"> Descending </b-dropdown-item-button>
                                                        <b-dropdown-item-button @click="fetchPostByStatus('pending')"> Pending </b-dropdown-item-button>
                                                        <b-dropdown-item-button @click="fetchPostByStatus('approved')"> Approved </b-dropdown-item-button>
                                                        <b-dropdown-item-button @click="fetchPostByStatus('suspended')"> Suspended </b-dropdown-item-button>
                                                        <b-dropdown-item-button @click="fetchPostByStatus('all')"> View all </b-dropdown-item-button>
                                                    </b-dropdown>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="mt-2">
                                        <strong>Filters:</strong> <span>Type: <span class="text-capitalize">{{postType}}</span></span> | <span>Sort: <span class="text-uppercase">{{postSort}}</span></span> | <span>Status: <span class="text-uppercase">{{postStatus}}</span></span>
                                    </div>
                                    <post-table @openPostView="openPostView($event)" :fetchItems="fetchPosts"/>
                                </div>
                                <div v-if="isPostView">
                                    <post-content @returnBack="closePostView()" @update="onUpdateContent($event)" :post="viewPost"/>
                                </div>
                            </div>
                            <div v-else class="card-body">
                                <router-link to="/languages" class="btn btn-light btn-sm">
                                    <i class="mdi mdi-chevron-left"></i> Back
                                </router-link>
                                <div v-if="isGroupAuthor">
                                    <p class="border-top pt-2">Language is under review. Four (4) other profiles must subscribe in order to be registered.</p>
                                </div>
                                <div v-else-if="group.belong">
                                    <p class="border-top pt-2">You are one of the teachers in this language group. Language is currently under review.</p>
                                </div>
                                <div v-else>
                                    <p class="border-top pt-2">Welcome {{activeUser.username}}, join us and share your linguistic expertise by becoming a language teacher, imparting your fluency and passion for {{group.name}} with eager learners! </p>
                                    <div class="mt-2">
                                        <b-button @click.prevent="joinGroup()" variant="primary"> Join Now!</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="group.status == 'approved' && group.belong" class="row">
                    <div class="col-12">
                       <essay-list />
                    </div>
                </div>
            </div>

            <div >
                <b-modal centered id="modal-2" v-model="uploadAlphaModal" 
                :title="editMode ? 'Upload alphabet' : 'Edit Alphabet'" header-close-variant="primary"
                title-class="font-18 text-primary" hide-footer @hide="initializeAll()">
                    <upload-alphabet v-if="uploadAlphaModal" @closeMe="initializeAll()"
                        :editMode="editMode" :editItem="editItem" />
                </b-modal>
                <b-modal centered id="modal-3" v-model="uploadWordModal" 
                :title="editMode ? 'Upload word' : 'Edit word'" header-close-variant="primary" 
                title-class="font-18 text-primary" hide-footer @hide="initializeAll()">
                    <upload-word v-if="uploadWordModal" @closeMe="initializeAll()"
                        :editMode="editMode" :editItem="editItem"/>
                </b-modal>
                <b-modal centered id="modal-4" v-model="uploadSentenceModal" 
                :title="editMode ? 'Upload phrase/sentence' : 'Edit phrase/sentence'" header-close-variant="primary"
                title-class="font-18 text-primary" hide-footer @hide="initializeAll()">
                    <upload-sentence v-if="uploadSentenceModal" @closeMe="initializeAll()"
                    :editMode="editMode" :editItem="editItem"/>
                </b-modal>
                <b-modal centered id="modal-5" v-model="uploadQuizModal" 
                :title="editMode ? 'Upload quiz' : 'Edit quiz'" header-close-variant="primary"
                title-class="font-18 text-primary" hide-footer @hide="initializeAll()">
                    <upload-quiz v-if="uploadQuizModal"  @closeMe="initializeAll()"
                    :editMode="editMode" :editItem="editItem"/>
                </b-modal>
                <b-modal centered id="modal-4" v-model="uploadEssayModal" 
                :title="editMode ? 'Upload essay' : 'Edit essay'" header-close-variant="primary"
                title-class="font-18 text-primary" hide-footer @hide="initializeAll()">
                    <upload-essay v-if="uploadEssayModal" @closeMe="initializeAll()"
                    :editMode="editMode" :editItem="editItem"/>
                </b-modal>
                <b-modal centered scrollable id="modal-1" size="lg" v-model="showGuidelinesModal" title="Guidelines" header-close-variant="primary" title-class="font-18 text-primary" hide-footer>
                    <group-guidelines @resetData="resetData()"/>
                </b-modal>
            </div>
        </div>
        <is-loading v-else/>
	</div>
</template>

<script>
import PostContent from "@/components/group/PostContent.vue";
import GroupPortlet from "@/components/group/GroupPortlet.vue";
import UploadAlphabet from "@/components/forms/UploadAlphabet.vue"
import UploadWord from "@/components/forms/UploadWord.vue"
import UploadSentence from "@/components/forms/UploadSentence.vue"
import UploadQuiz from "@/components/forms/UploadQuiz.vue"
import UploadEssay from '@/components/forms/UploadEssay.vue';
import IsLoading from '@/components/IsLoading.vue';
import PostTable from '@/components/group/PostTable.vue';
import GroupGuidelines from '@/components/group/GroupGuidelines.vue';
import EssayList from '@/components/group/EssayList.vue';
import _ from 'lodash';

export default {
	components: {
        GroupPortlet,
        PostContent,
        UploadAlphabet,
        UploadWord,
        UploadSentence,
        UploadQuiz,
        PostTable,
        IsLoading,
        GroupGuidelines,
        EssayList,
        UploadEssay
	},
	data() {
		return {
            isLoading: true,
            isFetchingPosts: false,

            uploadAlphaModal:false,
            uploadWordModal:false,
            uploadSentenceModal:false,
            uploadQuizModal:false,
            uploadEssayModal: false,

            showGuidelinesModal:false,

            editMode: false,
            editItem: null,

            viewPost: null,
            isPostView: false,
            postSort: 'asc',
            postType: 'all',
            postStatus: 'all',

            postSearchQuery: "",
		}
	},
    watch: {
        "$route":"fetchGroup"
    },
	computed: {
        activeUser(){
            return this.$store.state.AppActiveUser
        },
        group(){
            return this.$store.state.groupList.group
        },
        isGroupAuthor(){
            return this.activeUser.id === this.group.founder_id
        },
	},
	methods: {
        joinGroup(){
            Swal.fire({
            title: "Are you sure?",
            text: "You are about to join this language group as a teacher",
            showCancelButton: true,
            confirmButtonColor: '#800020',
            cancelButtonColor: '#565656',
            confirmButtonText: 'Yes, I Agree',
            }).then((result) => {
                if (result.value) {
                   this.$http.post(`/groups/${this.group.id}/join`)
                    .then(response => {
                        if (response.data.success) {
                            this.fetchGroup();
                        }
                    }) 
                }
            })
        },
        openPostView(post){
            this.isPostView = true
            this.viewPost = post;
        },
        fetchPostByType(type){
            this.postType = type;
            this.isPostView = false;
            this.fetchPosts()
        },
        fetchPostByStatus(status){
            this.postStatus = status;
            this.isPostView = false;
            this.fetchPosts()
        },
        fetchPostBySort(sort){
            this.postSort = sort;
            this.isPostView = false;
            this.fetchPosts()
        },
        closePostView(){
            this.isPostView = false
        },
        onUpdateContent(item){
            this.editMode = true
            this.editItem = item
            if(item.type === 'alphabet'){
                this.uploadAlphaModal = true;
            }else if(item.type === 'word'){
                this.uploadWordModal = true;
            }else if(item.type === 'phrase'){
                this.uploadSentenceModal = true;
            }else if(item.type === 'quiz'){
                this.uploadQuizModal = true;
            }else if(item.type === 'essay'){
                this.uploadEssayModal = true;
            }
        },
        updateSearchQuery: _.debounce(function(string) {
            this.fetchPosts()
        }, 2000),
        fetchPosts(page, per_page = null){
            let payload = {group_id: this.$route.params.groupId, page: page || 1, sort_by: this.postSort, per_page: per_page || 50 }
            if(this.postType != 'all'){ payload.type = this.postType }
            if(this.postStatus != 'all'){ payload.status = this.postStatus }
            if(this.postSearchQuery){ payload.search = this.postSearchQuery }
            this.isFetchingPosts = true
            this.$store.dispatch("groupList/fetchPosts", payload)
            .then(response => this.isFetchingPosts = false)
        },
        fetchGroup(){
            this.initializeAll()
            this.isLoading = true
            this.$store.dispatch("groupList/fetchGroup", this.$route.params.groupId)
            .then((response) => {
                this.isLoading = false
                if(response.data.success){
                    if(response.data.data.belong) this.fetchPosts()
                }else{
                    this.$router.push({path: '/error-404'})
                }
            })
        },
        initializeAll(){
            this.uploadAlphaModal = false;
            this.uploadWordModal = false;
            this.uploadSentenceModal = false;
            this.uploadQuizModal = false;
            this.uploadEssayModal = false;
            this.editMode = false;
            this.editItem = null;
        },
	},
    created(){
        this.fetchGroup()
    },
    mounted() {
        this.$store.dispatch('togglePageHeaderFooter',{header:false, footer:false})

        this.$root.$on('bv::dropdown::show', bvEvent => {
            if(bvEvent.componentId === 'dropdown-step2' ) {
            this.isDropdown2Visible = true;
            }
        })
        this.$root.$on('bv::dropdown::hide', bvEvent => {
            if(bvEvent.componentId === 'dropdown-step2') {
            this.isDropdown2Visible = false;
            }
            if(this.isDropdown2Visible) {
             bvEvent.preventDefault()
            }
        })
	},
    beforeDestroy(){
        this.$store.dispatch('togglePageHeaderFooter',{header:true, footer:true})
    }
}
</script>


<style lang="scss">
.custom-dropdown-sm{
 .dropdown-menu.show{
    position:absolute !important
 }
}
</style>
