<template>
<div v-if="post" >
    <div>
        <div class="">
            <div class="media mt-1">
                <div class="media-body">
                    <span class="float-right"><small>{{post.created_at | date_time(1)}}</small></span>
                    <!-- <span class="float-right"><span> <span class="border-right cursor-pointer d-inline-block" v-b-tooltip.hover title="Endorse">{{post.endorses.length}} <i class="mdi mdi-thumb-up d-inline-block mr-1"></i></span> <span class="cursor-pointer" v-b-tooltip.hover title="Dispute"> {{post.disputes.length}} <i class="mdi mdi-thumb-down d-inline-block"></i></span></span></span> -->
                    <b-button @click.prevent="$emit('returnBack')" variant="light" size="sm">
                        <i class="mdi mdi-chevron-left"></i> View list
                    </b-button> <br>
                </div>
            </div>
        </div>
        <hr class="my-2" />
        <p>
            <span class="bg-soft-primary badge mr-2">Type: {{ post.type}}</span>
            <span class="bg-soft-success badge">Level: {{ post.level}}</span>
        </p>
        <dl class="row mt-3">
            <dt class="col-12 text-capitalize">{{post.type}}:</dt>
            <dd class="col-12"><div class="pl-3"><div v-html="preText(post.content)"></div></div></dd>
            <dt class="col-12" v-if="post.translation">Translation:</dt>
            <dd class="col-12" v-if="post.translation"><div class="pl-3"> <div v-html="preText(post.translation)"></div> </div></dd>
        </dl>
         <div v-if="post.type == 'quiz'">
            <ul class="list-group list-group-flush">
                <li class="list-group-item px-0 mb-0 bg-transparent">A.) {{post.option_a}}</li>
                <li class="list-group-item px-0 mb-0 bg-transparent">B.) {{post.option_b}}</li>
                <li class="list-group-item px-0 mb-0 bg-transparent">C.) {{post.option_c}}</li>
                <li class="list-group-item px-0 mb-0 bg-transparent">D.) {{post.option_d}}</li>
            </ul>
            <b-form-group label="The correct option is" class="mb-2" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-2"
                    :checked="post.answer"
                    :aria-describedby="ariaDescribedby"
                    name="correct_option"
                >
                    <b-form-radio :disabled="post.answer != 'A'" value="A">A</b-form-radio>
                    <b-form-radio :disabled="post.answer != 'B'" value="B">B</b-form-radio>
                    <b-form-radio :disabled="post.answer != 'C'" value="C">C</b-form-radio>
                    <b-form-radio :disabled="post.answer != 'D'" value="D">D</b-form-radio>
                </b-form-radio-group>
            </b-form-group>
        </div>
        <hr class="my-2" />
        
        <div v-if="post.voice_path" class="row">
            <div  class="col-sm-6 col-lg-5">
                <h5 class="mb-2">Voice Attachments</h5>
                 <div>
                    <audio
                        hieght="23"
                        controls
                        :src="absoluteUrl(post.voice_path)">
                            Your browser does not support the
                            <code>audio</code> element.
                    </audio>
                </div>
            </div>
            <div class="col-12"><hr /></div>
        </div>
        <div v-if="post.type == 'phrase' || post.type == 'word'">
            <a href="#" style="border-bottom:1px dashed #000; font-size:12px;" @click.prevent="showUsage = !showUsage;" class="mb-0">{{showUsage? 'Hide': 'Show' }} Usage Examples</a>
            <ol v-if="showUsage" class="mt-2 pl-3" >
                <li>
                    <span>
                        <dt>{{post.first_usage}} </dt>
                        <dd> Translation: {{post.first_usage_trans}}</dd>
                    </span>
                </li>
                <li>
                    <span>
                        <dt>{{post.second_usage}} </dt>
                        <dd> Translation: {{post.second_usage_trans}}</dd>
                    </span>
                </li>
                <li>
                    <span>
                        <dt>{{post.third_usage}} </dt>
                        <dd> Translation: {{post.third_usage_trans}}</dd>
                    </span>
                </li>
            </ol>
        </div>
        <div >
            <span v-if="isAuthor && post.status == 'suspended'">
                <p class="text-warning font-sm">This content was suspended! This happens when number of disputes are greater than endorsements. Below are reasons. Update Content to start afresh!</p>
                <ul class="mt-2 pl-3" >
                    <li v-for="(reson, vyres) in post.disputes" :key="vyres">
                        <span>
                            <dd> {{reson.reason}}</dd>
                        </span>
                    </li>
                </ul>
                <a @click.prevent="updateContent(post)" href="#" class="btn btn-success mr-2 btn-sm">
                   <i class="mdi mdi-file-document-edit-outline"></i> Edit
                </a>
            </span>
            <span v-if="isShowDisputeOrEdorse">
                <a @click.prevent="disputePost(post.id)" href="#" class="btn btn-secondary mr-2 btn-sm">
                    <i class="mdi mdi-thumb-down-outline mr-1"></i> Dispute
                </a>
                <a @click.prevent="endorsePost(post.id)" href="#" class="btn btn-success btn-sm">
                    <i class="mdi mdi-thumb-up-outline ml-1"></i> Endorse
                </a>
            </span>
            <span>
                <b-alert variant="info" :show="isEndorser" dismissible>
                    Info! You endorsed this content <b>&rArr;</b>
                </b-alert>
                <b-alert variant="info" :show="isDisputor" dismissible>
                    Info! You have disputed this content <b>&rArr;</b>
                </b-alert>
            </span>
        </div>
    </div>

    <b-modal centered scrollable id="modal-1" size="sm" v-model="showDisputeModal" title="Provide your Reasons" header-close-variant="primary" title-class="font-18 text-primary" hide-footer>
        <div class="row justify-content-end">
            <div class="col-12 d-flex align-items-center border-right">
                <div class="form-group w-100">
                    <textarea  v-model="dispute.reason" class="form-control " rows="3" placeholder="Your reasons..." v-validate="'max:200'" name="dispute"></textarea>
                </div>
            </div>
            <div class="col-12">
                <div class="text-center">
                    <button type="button" @click.prevent="submitDispute" class="btn btn-primary mt-2">
                        <i class="mdi mdi-content-save"></i> Submit
                    </button>
                </div>
            </div>
        </div>
    </b-modal>
</div>
</template>

<script>

export default {
    props:{
        post:{
            type: Object,
            default: null
        },
    },
    data(){
        return {
            showUsage: true,
            dispute:{
                reason: "",
            },
            review:{
                remark: "",
                score: null,
            },
            showDisputeModal: false,
        }
    },
    computed: {
        activeUser() {
            return this.$store.state.AppActiveUser
        },
        isAuthor(){
            return this.post.author_id === this.activeUser.id
        },
        isDisputor(){
            return this.post.disputes.map((d) => d.teacher_id).includes(this.activeUser.id)
        },
        isEndorser(){
            return  this.post.endorses.map((e) => e.teacher_id).includes(this.activeUser.id)
        },
        isShowDisputeOrEdorse(){
           return !this.isAuthor && !(['suspended','approved'].includes(this.post.status))
                 && !this.isEndorser && !this.isDisputor
        }
    },
    methods: {
        updateContent(post){
            this.$emit('update', post)
        },
        endorsePost(postId){
            Swal.fire({
            title: "Are you sure?",
            text: "You are about to Endorse a post",
            showCancelButton: true,
            confirmButtonColor: '#800020',
            cancelButtonColor: '#565656',
            confirmButtonText: 'Yes, Sure',
            showLoaderOnConfirm: true,
            }).then((result) => {
                if (result.value) {
                   this.$http.post(`/posts/${postId}/endorse`)
                    .then(response => {
                        if (response.data.success) {
                            this.$store.commit(`groupList/UPDATE_POST`, response.data.data)
                        }
                    }) 
                }
            })
        },
        disputePost(postId){
            this.dispute.id = postId;
            this.showDisputeModal = true;
        },
        submitDispute(){
            if(!this.dispute.reason){ this.alertError("Please provide reason"); return}
            const formData = new FormData()
            formData.append('data', JSON.stringify(this.dispute))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/posts/${this.dispute.id}/dispute`,formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.data.success){
                    this.$store.commit(`groupList/UPDATE_POST`, response.data.data)
                    this.resetForm();
                }
            })
        },
        resetForm(){
            this.dispute = {
                reason: ""
            }
            this.showDisputeModal = false
        },
    }

};
</script>

<style>

audio {
    width: 100%;
    height: 30px;
}
</style>
