<template>
  <form>
    <div class="form-group mb-3">
        <input v-model="data.score"  type="number" v-validate="{required: true, regex: '^\\+?([0-9])$' }"
            class="form-control" placeholder="Score (1 - 100)" name="score" />
    </div>
    <div class="form-group w-100">
        <textarea v-model="data.remark" class="form-control " rows="3" 
        placeholder="Write review..." v-validate="'max:500'" name="review"></textarea>
    </div>
    <div class="text-center">
        <button type="button" @click.prevent="submitReview" class="btn btn-primary mt-2">
            <i class="mdi mdi-content-save"></i> Submit
        </button>
    </div>
  </form>
</template>

<script>
export default {
    props:{
        essayId:{ 
            default: null
        }
    },
    data(){
        return{
            data:{
                remark: "",
                score: null,
            },
        }
    },
    methods:{
        submitReview(){
            const formData = new FormData()
            formData.append('data', JSON.stringify(this.data))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/essays/${this.essayId}/review`,formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.data.success){
                    this.$store.commit('groupList/UPDATE_ESSAY', response.data.data)
                    this.resetForm();
                }
            })
        },
        resetForm(){
            this.data = {
                remark: "",
                score: null,
            }
            this.$emit('closeMe');
        },
    }
}
</script>

<style>

</style>